:root {
  --p-swiper-full-page-height: 100vh;
}
.p-swiper-page {
  width: 100vw;
  height: var(--p-swiper-full-page-height);
  /* height: fill-available; */
  min-height: 100vh;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.p-swiper-page-vertical-layout {
  height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-snap-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  --scroll-behavior: smooth;
}
.p-swiper-page-horizontal-layout {
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  --scroll-behavior: smooth;
}
