.nft-order-modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #fff;
  z-index: 100;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 800px) {
  .nft-order-modal-wrapper {
    max-width: 500px;
    padding-top: 30px;
    height: calc(100vh - 56px);
    overflow-y: scroll;
  }
}

.nft-order-modal-top {
  padding-top: 70px;
}
.nft-order-modal-title {
  font-size: 25px;
  padding-bottom: 20px;
}

.nft-order-modal-fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.nft-order-modal-fields-item .title {
  text-transform: uppercase;
  font-size: 14px;
  padding-bottom: 8px;
}
.nft-order-modal-fields-item input {
  padding: 10px;
  border: 2px solid grey;
  border-radius: 8px;
  width: 100%;
}

.order-button {
  /* padding: 20px 40px; */
  padding: 11px 0;
  background: rgb(152, 227, 113);
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  transition: 0.2s ease;
  cursor: pointer;
}
.order-button:active {
  opacity: 0.7;
  transform: translateY(2px);
}
