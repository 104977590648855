* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Bagoss";
  src: url("./_assets/fonts/BagossExtended-Bold.woff2") format("woff2");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Bagoss";
}

:root {
  --vh: 1vh;
  /* document.documentElement.style.setProperty('--vh', `${vh}px`); */
}

.page-layout {
  padding: 15px;
  padding-top: 40px;
}

.fixed-layer {
  position: fixed;
  opacity: 0;
  z-index: 99;
  transition: 0.3s ease;
}
.fixed-layer-title {
  top: 15px;
  left: 15px;
  font-size: 50px;
  font-weight: bold;
}
.fixed-layer-contact {
  bottom: 15px;
  right: 25px;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: inherit;
}
.page-title {
  font-size: 150px;
  line-height: 130px;
  padding-top: 50px;
  font-weight: bold;
}
@media screen and (max-width: 700px) {
  .fixed-layer-title {
    font-size: 35px;
  }
  .fixed-layer-contact {
    font-size: 25px;
  }
  .page-title {
    font-size: 45px;
    line-height: 55px;
    padding-top: 20px;
  }
}

.app-page {
  position: relative;
}
.app-page-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.app-page-content-image-cover {
  max-width: 70%;
  max-height: 70%;
}
.app-page-content-image-description {
  padding: 10px 0;
  font-family: Arial;
  font-size: 13px;
  font-style: italic;
}
.app-page-content-text {
  width: 100%;
  height: 100%;
  padding: 94px 130px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 114px;
  font-family: Arial, Helvetica, sans-serif;
}
.app-page-content-text > div {
  flex: 1;
}
@media screen and (max-width: 600px) {
  .app-page-content-text {
    display: block;
    padding: 81px 69px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
  .app-page-content-text > div {
    flex: none;
  }
}

/* arrows */
.page-arrow {
  --arrow-size: 64px;
  position: absolute;
  width: var(--arrow-size);
  height: var(--arrow-size);
  cursor: pointer;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 48px;
  transition: 0.2s ease;
  opacity: 1;
  transition: 0.2s ease;
}
.page-arrow:active {
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  /* transform: scale(1.5); */
}
.page-arrow--down {
  bottom: 30px;
  left: calc(50vw - var(--arrow-size) / 2);
  background-image: url("./_assets/arrows/arrow-down.svg");
}
@media screen and (max-width: 600px) {
  .page-arrow--down {
    bottom: 60px;
  }
}

.page-arrow--up {
  top: 50px;
  left: calc(50vw - var(--arrow-size) / 2);
  background-image: url("./_assets/arrows/arrow-up.svg");
}
.page-arrow--left {
  left: 30px;
  top: calc(50vh - var(--arrow-size) / 2);
  background-image: url("./_assets/arrows/arrow-left.svg");
}
.page-arrow--right {
  right: 30px;
  top: calc(50vh - var(--arrow-size) / 2);
  background-image: url("./_assets/arrows/arrow-right.svg");
}
@media screen and (max-width: 700px) {
  .page-arrow--left {
    left: 0px;
  }
  .page-arrow--right {
    right: 0px;
  }
}

/* blink text */
/* .blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
} */
/* 
.access-nft-button {
  transition: 1s ease;
}
.access-nft-button:active {
  transform: scale(10);
} */

.in-situ-gallery-grid {
  /* padding: 0 40px; */
  padding-top: 40px;
  padding-bottom: 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  /* width: 100vw; */
  gap: 40px;
}
.in-situ-gallery-grid > img {
  max-height: calc(100vh / 1.5 - 100px);
  max-width: calc(100vw / 2 - 100px);
}

@media screen and (max-width: 600px) {
  .in-situ-gallery-grid {
    grid-template-columns: 1fr;
  }
  .in-situ-gallery-grid > img {
    max-height: 40vh;
    max-width: 80vw;
  }
}
