.nft-page {
}
.nft-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.nft-page-title {
  padding-top: 20px;
  font-size: 60px;
  font-weight: bold;
}
.nft-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: white; */
  padding-top: 100px;
  /* height: 100vh; */
}
@media screen and (max-width: 600px) {
  .nft-image-container {
    /* height: auto; */
    padding-top: 80px;
  }
}

.nft-image-container img {
  height: 50vh;
  /* width: 70vw; */
  object-fit: contain;
  background-color: white;
  padding: 36px;
  border-radius: 4px;
}

/* color picker */
.nft-color-picker {
  width: 90vw;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  /* max-width: 900px; */
}
.nft-color-picker-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nft-color-picker-title {
  padding: 10px 0;
}
.nft-color-list {
  display: flex;
  width: 100%;
  overflow-x: scroll;
}
.nft-color-list > div {
  padding: 10px 15px;
  white-space: nowrap;
  cursor: pointer;
}
.nft-color-list > div.opened {
  background: rgba(255, 255, 255, 0.3);
}
.nft-choose-color {
  background: rgba(255, 255, 255, 0.3);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 15px 15px;
  width: 100%;
  gap: 15px;
}
.nft-choose-color > div {
  height: 20px;
  border: 3px solid transparent;
}

@media screen and (min-width: 700px) {
  .nft-choose-color {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.nft-choose-color > div.selected {
  border-color: rgb(0, 255, 0);
}

.nft-page-text {
  text-align: center;
  padding-top: 150px;
  padding-right: 100px;
  padding-left: 100px;
  font-family: Arial, Helvetica, sans-serif;
}
@media screen and (max-width: 700px) {
  .nft-page-text {
    padding-top: 150px;
    padding-right: 50px;
    padding-left: 50px;
  }
}
