.nft-image-container-svg-container {
  width: auto;
  /* height: 50vh; */
  resize: both;
  overflow: auto;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
@media screen and (max-width: 700px) {
  .nft-image-container-svg-container {
    /* width: 55vw;
    height: 55vh; */
  }
}
@media screen and (min-width: 700px) {
  .nft-image-container-svg-container {
    /* height: 60vh; */
  }
}
.nft-image-container-svg-container > svg {
  height: calc(100vh - 300px);
  width: 100%;
}

@media screen and (max-width: 700px) {
  .nft-image-container-svg-container > svg {
    width: 55vw;
    height: 100%;
  }
}

.nft-current-edited-path {
  animation: animateCurrentEditedPath 0.8s linear infinite;
}

@keyframes animateCurrentEditedPath {
  0% {
    fill: red;
    stroke: red;
  }
  50% {
    fill: transparent;
    stroke: transparent;
  }
  100% {
    fill: red;
    stroke: red;
  }
}

.order-now-button {
  padding: 10px 20px;
  background: rgb(152, 227, 113);
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  transition: 0.2s ease;
  border: 2px solid #000;
  cursor: pointer;
}
.order-now-button:active {
  opacity: 0.7;
  transform: translateY(2px);
}
