.admin-text-editor-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background: #00000055;
}
.admin-text-editor {
  width: 500px;
  min-height: 100px;
  background: #fff;
  padding: 10px;
}
.admin-text-editor-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
