.admin-page-list {
  max-width: 700px;
  padding: 10px;
  padding-bottom: 50px;
}

.admin-page-list-page {
  padding: 10px;
  background: #eee;
  border-radius: 10px;
  margin-top: 15px;
  border: 1px solid #ccc;
}
.admin-page-list-page-fields {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
  max-width: 300px;
}
.admin-page-list-page-bottom-actions {
  padding-top: 30px;
  display: flex;
  gap: 10px;
}

.admin-page-list-page-medias {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0;
}
.admin-page-list-page-medias-item {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
}
.admin-page-list-page-medias-item img {
  width: 120px;
  height: 120px;
  object-fit: contain;
  background: #ccc;
}

.admin-page-list-page-medias-item-new {
  border: 2px solid black;
  width: 140px;
  height: 140px;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.admin-page-list-page-medias-item-new input[type="text"] {
  width: 100%;
  margin-top: 20px;
}

.admin-page-list-page-medias-item-new input[type="file"] {
  width: 1px;
  height: 1px;
}

.admin-page-list-page-medias-item-text-preview {
  width: 140px;
  max-height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: monospace;
  font-size: 11px;
  padding-top: 10px;
}
